import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UrlConverter from './UrlConverter';

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        history('/login');
        return;
      }
      
      try {
        const response = await fetch('https://api.bucksburst.com/auth/adjust', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          history('/login');
        }
      } catch (error) {
        setIsAuthenticated(false);
        history('/login');
      }
    };
    
    validateToken();
  }, [history]);

  return isAuthenticated ? <UrlConverter /> : null; // Or any loading indicator if needed
};

export default ProtectedRoute;
