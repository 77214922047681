import React, { useState, useEffect } from 'react';
import './App.css';
import { Link, useLocation } from 'react-router-dom';


function AdminPanel() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  const handleWhitelistToggle = async (userId) => {
    const token = localStorage.getItem('token');
    const user = users.find(user => user.username === userId);
    if (!user) return;
    const response = await fetch('https://api.bucksburst.com/auth/adjust/admin/whitelist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ username: user.username, whitelist: !user.whitelisted })
    });
  
    if (!response.ok) {
      console.error('Error:', response.statusText);
      return;
    }
  
    const updatedUsers = users.map(u => {
      if (u.username === userId) {
        return { ...u, whitelisted: !u.whitelisted };
      }
      return u;
    });
    setUsers(updatedUsers);
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.bucksburst.com/auth/adjust/admin/getusers', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setUsers(data.users);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Search term state for filtering users
  const [searchTerm, setSearchTerm] = useState('');

  // Filter users based on search term
  const filteredUsers = users.filter(user =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const location = useLocation();

  return (
    <div className="admin-panel">
      <header className="admin-header">
        <h1>Users Management</h1>
        <div className="header-buttons">
          <Link to="/" className={`header-button ${location.pathname === '/' ? 'active' : 'notactive'}`}>Users</Link>
          {/* <Link to="/s2s" className={`header-button ${location.pathname === '/s2s' ? 'active' : 'notactive'}`}>s2s</Link> */}
          {/* Add more buttons here for other management options */}
        </div>
        <div className="user-info">
          {/* User info and logout functionality can go here */}
        </div>
      </header>
      <div className="content">
        <aside className="sidebar">
          {/* Navigation or additional controls can go here */}
        </aside>
        <input
          type="text"
          placeholder="Search by username..."
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
        <h2>Users - Total: {filteredUsers.length} | Whitelisted: {filteredUsers.filter(user => user.whitelisted).length} | Not Whitelisted: {filteredUsers.filter(user => !user.whitelisted).length}</h2>
        <main className="user-management">
          {isLoading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <section className="user-list">
              <ul className="users">
                {filteredUsers.map((user, index) => (
                  <li key={index} className="user" style={{ backgroundColor: user.whitelisted ? '#333' : '#554141' }}>
                  <div className="user-details">
                      <span className="username">{user.username}</span>
                      {/* Additional user details can be displayed here */}
                    </div>
                    <div className="user-actions">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={user.whitelisted}
                          onChange={() => handleWhitelistToggle(user.username)}
                        />
                        <span className="slider round"></span>
                      </label>
                      {/* More user-specific actions can be added here */}
                    </div>
                  </li>
                ))}
              </ul>
            </section>
          )}
          {/* Bottom frame */}
          <div className="bottom-frame"></div>
        </main>
      </div>
    </div>
  );
}

export default AdminPanel;