import React from 'react';
import './protected.css';
const LoginButton = () => {
  return (
    <div className='bodyfr'>
      <div className="containerfr">
        <div className="textfr">You are not authenticated, this is an admin panel!</div>
        <button
          className="login-button"
          onClick={() => window.location.href = 'https://discord.com/oauth2/authorize?client_id=1202517446058184756&response_type=code&redirect_uri=https://admin.adjust.lol/auth/discord/callback&scope=identify'}
        >
          Login to Discord
        </button>
      </div>
    </div>
  );
};

export default LoginButton;
